.client-selection-container {
  height: calc(100vh - 42px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  h1 {
    font-size: 36px;
    font-weight: medium;
    margin: 80px 0 0 0;
  }

  .spinner-wrapper {
    margin-top: -60px;
  }
}

.select-client-box {
  margin-top: 40px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 480px;
  background-color: #f0f0f0;
  height: fit-content;

  h2 {
    font-size: 24px;
    font-weight: medium;
    margin: 0 0 30px 0;
  }

  .client-box-elements {
    width: 100%;
  }

  .select-client-btn {
    display: block;
    margin: 30px 0 20px 0;
    width: 100%;
  }

  .select-client-title {
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: medium;
  }
}
