body,
html {
  background: url('background.jpg') !important;
  background-size: cover !important;
}

body {
  margin: 0;
}

.bbthat-wrapper {
  display: none;
}

.main,
.footer {
  max-width: 1440px;
  margin: 0 auto 0;
}

.main {
  display: flex;
  flex-direction: column-reverse;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #777c7f;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 9999s ease-out;
  -webkit-text-fill-color: #ffffff;
}

.main .tax-section,
.main .auth-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.main .tax-logo {
  text-align: center;
  width: 387px;
  margin: 25px 30px;
}

.main .tax-header {
  font-size: 50px;
  font-weight: 300;
  line-height: 122.6%;
  margin-top: 140px;
  margin-left: 63px;
  margin-bottom: 0;
}

.main .tax-text {
  margin-left: 63px;
}

.main .tax-text p,
.main .tax-text .p-text {
  font-size: 18px;
  font-weight: 100;
  line-height: 30px;
}

.main .tax-text .p-text {
  margin-left: 106px;
}

.main a.promotion-link:hover {
  text-decoration: underline;
}

.main .tax-footer,
.main .auth-footer {
  max-width: 1440px;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px;
  white-space: nowrap;
  font-size: 10px;
}

.main .tax-footer-nav a {
  margin: 0 20px 0 10px;
  color: #fff;
  text-decoration: none;
}

.main .tax-footer-nav a:hover {
  text-decoration: underline;
}

.main .tax-footer a {
  color: #fff;
  margin-right: 12px;
}

.main .tax-footer a,
.main .tax-footer a:active,
.main .tax-footer a:hover {
  background-color: none;
}

.main .auth-component {
  flex-grow: 1;
  max-width: 420px;
}

@media (min-height: 860px) {
  .main {
    height: 100vh;
  }
}

@media (max-width: 1140px) {
  .main {
    height: 100%;
  }
}

@media (min-width: 300px) {
  .main .tax-header {
    margin-left: 16px;
    margin-right: 27px;
  }

  .main .tax-text {
    margin-left: 15px;
    margin-right: 31px;
  }

  .main .tax-footer,
  .main .auth-footer {
    white-space: nowrap;
  }
}

@media (min-width: 1140px) {
  .bbthat-wrapper {
    display: initial;
    background: #000;
  }

  .main {
    display: flex;
    align-items: stretch;
    flex-direction: row;
  }

  .main .tax-section {
    overflow: hidden;
    width: 58.5%;
    align-items: baseline;
  }

  .main .tax-auth,
  .main .tax-promotion {
    position: relative;
    text-align: left;
    flex-grow: 1;
    padding: 0;
  }

  .main .tax-auth {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  .main .tax-promotion {
    flex-grow: initial;
  }

  .main .auth,
  .main .promotion-call {
    background: rgba(0, 0, 0, 0.68);
    padding: 30px 61px 30px 61px;
    width: 486px;
    border-radius: 16px;
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.16);
  }

  .main .promotion-call {
    margin-top: 35px;
    padding: 0;
  }

  .main a.promotion-link {
    color: var(--color-palette-blue-300);
    text-decoration: none;
  }

  .main .promotion-text {
    padding: 3px 25px;
  }

  .main .tax-logo {
    position: relative;
    left: 10%;
    width: 465px;
    text-align: left;
    flex-grow: 1;
  }

  .main .tax-header {
    margin-left: 30px;
    margin-right: 0px;
  }

  .main .tax-text {
    margin-left: 30px;
    margin-right: 0;
  }

  .main .tax-text-links {
    display: flex;
    margin-top: 60px;
  }

  .main .tax-text-links a:last-child {
    margin-right: 0;
  }

  .main .tax-footer,
  .main .auth-footer {
    left: 10%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 82px;
    margin-left: 50px;
    font-size: 10px;
    white-space: nowrap;
  }

  .main .c-footer {
    margin-right: 405px;
  }

  .main .auth-footer {
    align-self: flex-end;
    padding: 0 32px;
    margin-left: 95px;
  }
}
