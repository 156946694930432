.client-change-container {
  height: calc(100vh - 42px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  h1 {
    font-size: 36px;
    font-weight: medium;
    margin: 80px 0 0 0;
  }
}
.client-change-btn-container {
  margin-top: 30px;
}
.client-change-error-message-text {
  margin-top: 20px;
  font-size: 16px;

  span {
    font-weight: 600;
  }
}
