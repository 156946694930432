#wkp-editor-container {
  div.formula-bar-item.wkp-linking-custom-formula-bar span {
    color: #0046ad;
    cursor: pointer;

    &:hover {
      color: #002761;
      text-decoration: underline;
    }

    &.readonly {
      color: #333;
      cursor: default;

      &:hover {
        text-decoration: none;
      }
    }

    &.remove-wkp-link {
      margin-left: 10px;
      vertical-align: bottom;

      svg path {
        fill: red;
      }
      img {
        display: inline;
        margin-right: 5px;

        svg {
          fill: #0046ad;
        }
      }
    }
  }

  div.formula-bar-item.wkp-linking-custom-formula-bar + div.formula-bar-item.formulaBarText {
    display: none;
  }

  .wkp-top-bar {
    margin-bottom: 2px;
    margin-top: 2px;
    min-height: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spreadWrapper .wkp-watermark {
    position: fixed;
    right: 80px;
    bottom: 80px;
    pointer-events: none;
    font-size: 24px;
    color: red;
    opacity: 0.3;
    cursor: default;
    font-weight: 600;
  }
}

.wkp-spreadsheet-container {
  border-bottom: 1vh solid black;
}

.wkp-dot-loader {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #0073ff;
  color: #0073ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: 5px 10px;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #0073ff;
    color: #0073ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: #0073ff;
    color: #0073ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #0073ff;
  }
  50%,
  100% {
    background-color: white;
  }
}

.wkp-tooltip-toggle-helper {
  left: -999px;
  position: absolute;
  top: -10%;
  z-index: 111;
  background-color: white;

  button {
    height: auto;
    padding: 2px;
  }
}

.wkp-tp-alert-message {
  font-weight: 500;
  max-width: 550px;
  b {
    text-decoration: underline;
  }
}

.wkp-tooltip-toggle-popover {
  strong {
    display: block;
  }

  a {
    display: flex;

    i {
      padding-left: 5px;
    }
  }
}

#designer {
  position: relative;
  z-index: 1;
  height: calc(100vh - 70.141px);
  padding-right: 42px;
}

.data-link-sync-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #0073ff;
  color: white;
  font-size: 14px;
  padding: 5px;
  border: none;
  height: 28px;
  margin-top: 2px;
  position: absolute;
  cursor: pointer;
}

.data-link-sync-button:hover {
  background-color: rgb(43, 142, 255);
}

.data-link-sync-button-tooltip {
  position: fixed;
  background-color: black;
  color: white;
  padding: 8px;
  font-size: 12px;
  text-align: center;
  line-height: 1;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 9999;
  white-space: normal;
  margin-top: 3px;

  top: 100%;
  left: 0;
  transform: translateX(0);
}

.data-link-sync-button-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid black;
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
}

.data-link-sync-button-icon {
  transform: rotate(270deg);
  margin-right: 8px;
  cursor: pointer;
}
