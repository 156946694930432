#spreadsheet-designer
  .gc-ribbon-bar
  .ribbon-panel-groups
  .ribbon-panel-group
  .ribbon-panelgrouplabel-container
  .ribbon-button-item
  .ribbon-button-item-icon.ribbon-button-indicator-icon {
  padding: 2px !important;
}

#spreadsheet-designer .gc-ribbon-bar .ribbon-navigation {
  padding-top: 0;
  margin-bottom: 3px;
}

#spreadsheet-designer .gc-ribbon-bar .ribbon-navigation .ribbon-navigation-item.ribbon-navigation-item-active {
  background: inherit;
  border: 0;
  border-bottom: 4px solid #0073ff;
}

#spreadsheet-designer .gc-ribbon-bar .ribbon-navigation .fileButton {
  background-color: #fff;
  color: black;
}

#spreadsheet-designer .gc-ribbon-bar .ribbon-navigation .fileButton:hover {
  background-color: #f2f2f2;
}

#spreadsheet-designer .gc-ribbon-bar .ribbon-navigation .ribbon-navigation-item:hover {
  background-color: #f2f2f2;
}

#spreadsheet-designer
  .gc-ribbon-bar
  .ribbon-panel-groups
  .ribbon-panel-group
  .ribbon-panelgrouplabel-container
  .ribbon-panel-group-label {
  font-size: 11px;
  color: #666;
}

#spreadsheet-designer .gc-ribbon-bar .ribbon-navigation .ribbon-navigation-item .ribbon-navigation-menu {
  color: #000;
}

#spreadsheet-designer .gc-formulaBar .mainBar .formulaBarText {
  font-size: 14px;
}

#spreadsheet-designer .gc-ui-contextmenu-hover {
  background-color: #c0ddf3;
}

.dialog-content legend {
  /* Override UX toolkit */
  font-size: 14px;
  margin-bottom: 10px;
  border-bottom: 0;
  width: auto;
  padding: 0 3px;
  color: #666;
}

#spreadsheet-designer .gc-spinner .input-group-addon {
  padding: 0; /* Override padding added by UXToolkit causing number steppers to be messed up */
}

/* DIALOG */
.gc-sjsdesigner-dialog,
.gc-sjsdesigner-dialog .dialog-content,
.gc-sjsdesigner-dialog .dialog-footer {
  background: white;
}
.gc-sjsdesigner-dialog .dialog-footer {
  padding-bottom: 15px;
}
.gc-sjsdesigner-dialog .dialog-content {
  padding-top: 0;
}
.gc-tab-control .tab-header .tab-header-item,
.gc-tab-control .tab-header {
  background-color: #f2f2f2;
}
.gc-font-preview {
  height: 113px;
}
.gc-font-effects {
  height: 116px;
}
.gc-tab-control .tab-header {
  overflow: visible; /* Needed on Sheet settings dialog, Headers tab (where there are 2 sets of tabs. Without this style the 2 rows of tabs were overlapping ) */
}

/* Data validation dialog */
.gc-data-validation-dialog
  .tab-content-item:first-child
  div:not(.gc-column-set):not(.container):not(.gc-flex-component):not(.gc-range-select):not(.range-select-button):not(
    .combo-text
  ):not(.gc-combo-editor-container) {
  margin-bottom: 7px !important;
}

/* ###################### */
/* Insert chart dialog, Combo tab */
span.series-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 122px;
  display: inline-block;
}
.series-container .series-content {
  height: 138px;
}

/* Status bar */
#spreadsheet-designer .gc-statusbar {
  background-color: #000;
}

/* File sidebar */
#spreadsheet-designer .gc-file-menu-category {
  background-color: #0073ff;
  width: 35px;
}
#spreadsheet-designer .gc-file-menu-category .back-button-container {
  margin-left: -2rem;
}
#spreadsheet-designer .gc-file-menu-category .back-button-container .back-button-icon {
  width: 20px;
  height: 20px;
}
#spreadsheet-designer .full-panels {
  position: fixed !important;
  box-shadow: 2px 4px 4px 1px;
  max-width: 20% !important;
  transition: max-width 1s;
  margin-top: 40px !important;
  margin-bottom: 30px;
}
@media (max-width: 1190px) {
  #spreadsheet-designer .full-panels {
    max-width: 28% !important;
  }
}
.gc-file-menu-category .seprater-line {
  display: none;
}
#spreadsheet-designer .file-menu-category-list {
  margin-left: 4rem;
  position: absolute;
  margin-top: -2rem;
  width: 25rem;
}
#spreadsheet-designer .gc-file-menu-category .gc-list-control-item {
  background-color: white;
  color: black;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  align-items: center;
  min-height: 5px;
}
#spreadsheet-designer .gc-columnHeader-normal {
  background-color: white;
  color: black;
  font-size: 15px;
  cursor: pointer;
  align-items: center;
  min-height: 5px;
}
#spreadsheet-designer .gc-list-control-item-text {
  margin-left: 3px;
  padding: 6px !important;
}
#spreadsheet-designer .gc-list-control-selected-item {
  background-color: white !important;
}
#spreadsheet-designer .gc-list-control-item:hover {
  background-color: #e1e1e1 !important;
}
#spreadsheet-designer .file-menu-watermark {
  color: #666;
  margin-top: 28px;
  margin-bottom: 10px;
  margin-left: -60px;
  font-weight: 400;
  font-size: smaller;
}
#spreadsheet-designer .file-menu-category-list .gc-list-control-item {
  margin-bottom: 15px !important;
}
#spreadsheet-designer .file-menu-category-list .gc-list-control-item-text {
  margin-top: -24px;
}
#spreadsheet-designer .file-menu-category-list .gc-list-control-container {
  position: fixed;
  max-width: 20% !important;
}
#spreadsheet-designer .file-menu-category-list .gc-list-control-item .save-as.file-menu-watermark {
  margin-left: -54px !important;
}

#spreadsheet-designer .file-menu-category-list ~ .file-menu-category-list {
  display: none !important;
}
