html {
  box-sizing: border-box;
}
html,
.moonfish {
  color: var(--color-text);
  background-color: var(--color-background);
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: var(--font-family-default);
}
/* Scroll bar styling */
body {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar-thumb) var(--colors-scrollbar-track);
}
/* Webkit scrollbar definitions */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--colors-scrollbar-thumb);
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background-color: var(--colors-scrollbar-track);
}
/* Headers, links, text */
/**
  elements with the class bbui-link, or any anchor under an element with
  the class bbui-link-wrapper gets this styling
  */
.moonfish a,
.m-link,
[data-m-link='true'],
.m-link-wrapper a,
[data-m-link-wrapper='true'] a {
  color: var(--color-link);
  text-decoration: underline;
}
.moonfish a:visited,
.m-link:visited,
[data-m-link='true']:visited,
.m-link-wrapper a:visited,
[data-m-link-wrapper='true'] a:visited {
  color: var(--color-link-visited);
}
.moonfish a:hover,
.m-link:hover,
[data-m-link='true']:hover,
.m-link-wrapper a:hover,
[data-m-link-wrapper='true'] a:hover,
.moonfish a:active,
.m-link:active,
[data-m-link='true']:active,
.m-link-wrapper a:active,
[data-m-link-wrapper='true'] a:active {
  background: var(--color-link-hover-background);
  outline: solid 2px var(--color-link-hover-background);
  outline-offset: 0;
}
.moonfish h1,
.m-large-title {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-xxxlarge);
  font-family: var(--font-family-accent);
  line-height: var(--size-line-height-xlarge);
}
.moonfish h1[data-variant='page-title'],
.m-page-title {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-xxlarge);
  font-family: var(--font-family-accent);
  line-height: var(--size-line-height-xlarge);
}
.moonfish h2,
.m-section-title {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-medium);
  font-family: var(--font-family-accent);
  line-height: var(--size-line-height-large);
}
.moonfish h3,
.m-sub-section-title {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-base);
  font-family: var(--font-family-accent);
  line-height: var(--size-line-height-medium);
}
.moonfish h4,
.m-group-title {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-base);
  font-family: var(--font-family-accent);
  line-height: var(--size-line-height-small);
}
.moonfish h5 .m-selection-label {
  font-weight: var(--font-weight-demi);
  font-size: var(--size-font-small);
  font-family: var(--font-family-accent);
  line-height: var(--size-line-height-xsmall);
  text-transform: uppercase;
}
.m-label {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-base);
  line-height: var(--size-line-height-small);
  text-transform: capitalize;
}
.m-form-section-label {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  line-height: var(--size-line-height-xsmall);
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.moonfish label,
.m-form-label {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-base);
  line-height: var(--size-line-height-small);
}
.moonfish p,
.text {
  font-weight: var(--font-weight-normal);
  font-size: var(--size-font-medium);
  line-height: var(--size-line-height-medium);
}
.moonfish p[data-variant='larger'],
.m-larger-text {
  font-weight: var(--font-weight-normal);
  font-size: var(--size-font-large);
  line-height: var(--size-line-height-large);
}
.moonfish p[data-variant='caption'],
.m-caption {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  line-height: var(--size-line-height-xsmall);
}
.moonfish blockquote,
.m-quotation {
  font-weight: var(--font-weight-normal);
  font-size: var(--size-font-large);
  line-height: var(--size-line-height-large);
}
.m-hint {
  font-weight: var(--font-weight-normal);
  font-size: var(--size-font-base);
  line-height: var(--size-line-height-small);
}
.moonfish h2[data-variant='big-hero'],
.m-big-hero {
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-xxxlarge);
  line-height: var(--size-line-height-xlarge);
}
.moonfish h2[data-variant='small-hero'],
h2.m-small-hero {
  font-weight: var(--font-weight-demi);
  font-size: var(--size-font-xxlarge);
  line-height: var(--size-line-height-xlarge);
}
.m-code-block {
}
.m-code {
}
