.fake-banner-wrapper {
  .bbna-navbar-hat-slim {
    background-color: #000;
    border-color: #000;
    z-index: 3;
    i,
    .navbar-nav > li > a,
    .bbna-icon-apps > i {
      font-size: 18px;
      color: white;
    }
    .bbna-icon-apps > i {
      background: white;
    }
    .icon-menu-apps {
      vertical-align: middle;
    }
    .name-menu-apps {
      margin-left: 10px;
      padding-right: 30px;
    }
    .item-menu-apps {
      height: 50px;
    }

    .navbar-nav > li > a {
      &:focus,
      &:hover {
        background-color: #444;
        cursor: pointer;
        color: white;
      }

      &:hover .navbar-button-text {
        color: white;
      }

      .navbar-button-text {
        margin-left: 7px;
        font-weight: 400;
        letter-spacing: 0.6px;
        vertical-align: middle;
        font-size: 14px;
      }

      .navbar-button-icon {
        height: fit-content;
      }

      span.bbui-icon {
        vertical-align: middle;
      }
    }
    .dropdown-menu-apps > li > a {
      font-size: 16px;
      font-weight: 450;
      &:focus,
      &:hover {
        background-color: #e1e1e1;
      }
    }
    .dropdown-header {
      font-size: 12px;
      font-weight: 450;
    }
  }

  .navbar-header {
    .navbar-button-text {
      color: #909090;
      font-size: 16px;
      margin-left: 7px;
      letter-spacing: 0.6px;
      display: table-cell;
      height: 40px;
      vertical-align: middle;
      padding-top: 5px;
    }
  }

  .help {
    li > a {
      cursor: pointer;
    }
  }

  @keyframes neonBlink {
    from {
      color: '#fff';
      text-shadow:
        0 0 5px #ff00ff,
        0 0 10px #ff00ff,
        0 0 20px #ff00ff,
        0 0 40px #ff00ff,
        0 0 80px #ff00ff;
    }
    to {
      color: '#000';
      text-shadow:
        0 0 2px #9e679e,
        0 0 4px #9e679e,
        0 0 8px #9e679e,
        0 0 16px #9e679e,
        0 0 32px #9e679e;
    }
  }
}
