.clients-dropdown {
  margin-left: 12px;
  .dropdown-menu-clients {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }
  .app-link {
    height: 32px;
    margin-left: 10.6px !important;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap-mode: nowrap;
    }
  }
  li {
    padding-left: 0px !important;
  }

  .client-picker-header {
    padding-bottom: 3px !important;
    padding-top: 0px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 40px;

    img {
      height: 28px;
      width: 28px;
    }

    div {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      gap: 4px;

      .caret-down {
        height: 8px;
        width: 8px;
        margin-bottom: 7px;
        margin-left: 3px;
      }
    }
    span {
      color: #cdcdcd !important;
      font-size: 14px;
      padding-bottom: 1px;
    }
  }
  .clients-header {
    margin-left: 14px;
    margin-bottom: 5px !important;
  }
  .clients-divider {
    height: 1px;
    margin-top: 13px;
    background-color: #646464;
  }
  .clients-container {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;

    scrollbar-width: thin !important;
    scrollbar-color: #646464 transparent !important;

    &::-webkit-scrollbar {
      width: 8px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #646464 !important;
      border-radius: 100px !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #808080 !important;
    }
  }
  .clients-manager {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: conter;
    gap: 7px;
    color: white;
    padding-left: 16px;
  }
}

.clients-manager-link:hover {
  .clients-manager {
    background-color: rgb(55, 55, 55);
    cursor: pointer;
    color: white;
  }
}
