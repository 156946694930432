/**
 * Do not edit directly
 * Generated on Sat, 11 Jun 2022 21:44:16 GMT
 */

:root {
  --time-animation-duration: 0.3s;
  --animation-timing-function: ease-in-out;
  --color-palette-transparent: rgba(255, 255, 255, 0);
  --color-palette-transparent-black: rgba(0, 0, 0, 0.5);
  --color-palette-white: #ffffff;
  --color-palette-black: #000000;
  --color-palette-grey-50: #f8f8f8;
  --color-palette-grey-100: #f0f0f0;
  --color-palette-grey-125: #ebebeb;
  --color-palette-grey-150: #e1e1e1;
  --color-palette-grey-200: #d7d7d7;
  --color-palette-grey-250: #cdcdcd;
  --color-palette-grey-300: #bebebe;
  --color-palette-grey-350: #a5a5a5;
  --color-palette-grey-400: #8c8c8c;
  --color-palette-grey-450: #787878;
  --color-palette-grey-500: #646464;
  --color-palette-grey-550: #505050;
  --color-palette-grey-600: #414141;
  --color-palette-grey-650: #373737;
  --color-palette-grey-700: #282828;
  --color-palette-grey-750: #232323;
  --color-palette-grey-800: #1e1e1e;
  --color-palette-grey-850: #191919;
  --color-palette-grey-900: #141414;
  --color-palette-red-50: #fee6eb;
  --color-palette-red-100: #ffa7af;
  --color-palette-red-200: #ff7486;
  --color-palette-red-300: #fe415c;
  --color-palette-red-400: #fd2d4a;
  --color-palette-red-500: #e51e3c;
  --color-palette-red-600: #c31834;
  --color-palette-red-700: #a0122c;
  --color-palette-red-800: #7d0c24;
  --color-palette-red-900: #5c061c;
  --color-palette-red-1000: #390014;
  --color-palette-orange-50: #ffddd3;
  --color-palette-orange-100: #fdc8b7;
  --color-palette-orange-200: #fdac93;
  --color-palette-orange-300: #fc9170;
  --color-palette-orange-400: #fb754c;
  --color-palette-orange-500: #fa5a28;
  --color-palette-orange-600: #d64d22;
  --color-palette-orange-700: #b3401d;
  --color-palette-orange-800: #8f3317;
  --color-palette-orange-900: #6b2711;
  --color-palette-orange-1000: #471a0b;
  --color-palette-amber-100: #ffdfb7;
  --color-palette-amber-200: #ffd093;
  --color-palette-amber-300: #ffc070;
  --color-palette-amber-400: #ffb04c;
  --color-palette-amber-500: #ffa028;
  --color-palette-amber-600: #db8922;
  --color-palette-amber-700: #b6721d;
  --color-palette-amber-800: #925b17;
  --color-palette-amber-900: #6d4511;
  --color-palette-amber-1000: #492e0b;
  --color-palette-yellow-100: #fff9cc;
  --color-palette-yellow-200: #fff399;
  --color-palette-yellow-300: #ffed66;
  --color-palette-yellow-400: #ffe733;
  --color-palette-yellow-500: #ffe100;
  --color-palette-yellow-600: #d7be00;
  --color-palette-yellow-700: #b09b00;
  --color-palette-yellow-800: #887800;
  --color-palette-yellow-900: #615500;
  --color-palette-yellow-1000: #393200;
  --color-palette-green-100: #c5facf;
  --color-palette-green-200: #8af59e;
  --color-palette-green-300: #50f06e;
  --color-palette-green-400: #35d655;
  --color-palette-green-500: #1bbc3c;
  --color-palette-green-600: #00a223;
  --color-palette-green-700: #00851c;
  --color-palette-green-800: #006716;
  --color-palette-green-900: #004a0f;
  --color-palette-green-1000: #002c09;
  --color-palette-teal-100: #dffcff;
  --color-palette-teal-200: #bff9ff;
  --color-palette-teal-300: #80f3ff;
  --color-palette-teal-400: #40ecff;
  --color-palette-teal-500: #00e6ff;
  --color-palette-teal-600: #00c2d7;
  --color-palette-teal-700: #009eb0;
  --color-palette-teal-800: #007b88;
  --color-palette-teal-900: #005761;
  --color-palette-teal-1000: #003339;
  --color-palette-blue-50: #f3f8ff;
  --color-palette-blue-100: #cce3ff;
  --color-palette-blue-200: #99c7ff;
  --color-palette-blue-300: #66abff;
  --color-palette-blue-400: #2b8eff;
  --color-palette-blue-500: #0073ff;
  --color-palette-blue-600: #0062dd;
  --color-palette-blue-700: #0051ba;
  --color-palette-blue-800: #004098;
  --color-palette-blue-900: #003075;
  --color-palette-blue-1000: #001f53;
  --color-palette-blue-1100: #001232;
  --color-palette-blue-darkest: #0a7daf;
  --color-palette-purple-50: #f8e5fb;
  --color-palette-purple-100: #edd0ff;
  --color-palette-purple-200: #e4b9ff;
  --color-palette-purple-300: #daa2ff;
  --color-palette-purple-400: #d18aff;
  --color-palette-purple-500: #c873ff;
  --color-palette-purple-600: #ab63db;
  --color-palette-purple-700: #8f52b6;
  --color-palette-purple-800: #724292;
  --color-palette-purple-900: #56316d;
  --color-palette-purple-1000: #392149;
  --color-palette-magenta-50: #fcdff4;
  --color-palette-magenta-100: #fbb7e8;
  --color-palette-magenta-200: #f993dd;
  --color-palette-magenta-300: #f770d2;
  --color-palette-magenta-400: #f54cc6;
  --color-palette-magenta-500: #f328bb;
  --color-palette-magenta-600: #d022a0;
  --color-palette-magenta-700: #ae1d86;
  --color-palette-magenta-800: #8b176b;
  --color-palette-magenta-900: #681150;
  --color-palette-magenta-1000: #450b35;
  --color-palette-pink-dark: #ea60a7;
  --color-palette-pink-light: #f697c0;
  --color-palette-outline-default: #767676;
  --color-fishtank-black: #292e31;
  --color-fishtank-gray-dark: #505558;
  --color-fishtank-gray: #777c7f;
  --color-fishtank-gray-light: #9ea3a6;
  --color-fishtank-gray-lighter: #c5cacd;
  --color-fishtank-gray-lightest: #edf2f5;
  --color-fishtank-white: #ffffff;
  --color-fishtank-transparent: rgba(0, 0, 0, 0);
  --color-fishtank-disabled: #edf2f5;
  --color-fishtank-outline-default: #767676;
  --color-fishtank-dark-mode-text-default: #3b3b3b;
  --color-fishtank-dark-mode-text-darker: #858585;
  --color-fishtank-light-mode-text-default: #efefef;
  --color-fishtank-background-default: #f8f8f8;
  --color-fishtank-background-darker: #f2f2f2;
  --color-fishtank-link-default: #267abd;
  --color-fishtank-link-visited: #7f57b8;
  --color-fishtank-link-dark-bg: #accce5;
  --color-fishtank-notification-1: #ea60a7;
  --color-fishtank-notification-2: #ffb511;
  --color-fishtank-notification-3: #0d9ddb;
  --color-fishtank-highlight-1: #fff8b0;
  --color-fishtank-highlight-2: #e3f7ff;
  --color-fishtank-highlight-3: #e0ffe0;
  --color-fishtank-action-darkest: #30855c;
  --color-fishtank-action-darker: #399e6e;
  --color-fishtank-action-default: #3da774;
  --color-fishtank-action-lighter: #49b88b;
  --color-fishtank-action-lightest: #ebf6f1;
  --color-fishtank-selected-darkest: #0a7daf;
  --color-fishtank-selected-darker: #0d94cf;
  --color-fishtank-selected-default: #0d9ddb;
  --color-fishtank-selected-lighter: #0fb0e2;
  --color-fishtank-selected-lightest: #e7f5fb;
  --color-fishtank-error-darkest: #b43931;
  --color-fishtank-error-darker: #d5443a;
  --color-fishtank-error-default: #e1483e;
  --color-fishtank-error-lighter: #e7564a;
  --color-fishtank-error-lightest: #fbeceb;
  --color-fishtank-warning-darkest: #cc900d;
  --color-fishtank-warning-darker: #f2ab10;
  --color-fishtank-warning-default: #ffb511;
  --color-fishtank-warning-lighter: #ffc314;
  --color-fishtank-warning-lightest: #fff7e7;
  --color-fishtank-secondary-darker: #e0e5e8;
  --color-fishtank-secondary-default: #edf2f5;
  --color-fishtank-secondary-lighter: #f6f8fa;
  --color-fishtank-bna-blue: #17509f;
  --color-fishtank-blaw-blue: #0d9ddb;
  --color-fishtank-btax-orange: #ea7815;
  --color-fishtank-btax-blue: #2785d6;
  --color-fishtank-theme-antitrust: #57534a;
  --color-fishtank-theme-banking: #379851;
  --color-fishtank-theme-bankruptcy: #c52700;
  --color-fishtank-theme-benefits: #ea6a02;
  --color-fishtank-theme-corporate: #8a5bb6;
  --color-fishtank-theme-ediscovery: #f64a56;
  --color-fishtank-theme-environment: #6dc470;
  --color-fishtank-theme-health: #0d3b91;
  --color-fishtank-theme-ip: #1e95c1;
  --color-fishtank-theme-labor: #00c18c;
  --color-fishtank-theme-securities: #45bde9;
  --color-fishtank-theme-telecom: #6a8c8a;
  --color-fishtank-dataviz-1: #63b995;
  --color-fishtank-dataviz-2: #1e4882;
  --color-fishtank-dataviz-3: #f87060;
  --color-fishtank-dataviz-4: #1aa2af;
  --color-fishtank-dataviz-5: #ffb511;
  --color-fishtank-dataviz-6: #9357c8;
  --color-fishtank-dataviz-7: #f697c0;
  --color-fishtank-dataviz-8: #2ecc71;
  --color-fishtank-dataviz-9: #34495e;
  --color-fishtank-dataviz-10: #ff6d10;
  --color-fishtank-dataviz-positive: #2ecc71;
  --color-fishtank-dataviz-negative: #f87060;
  --size-font-xsmall: 0.625rem; /* 10px */
  --size-font-small: 0.75rem; /* 12px */
  --size-font-base: 0.875rem; /* 14px */
  --size-font-medium: 1rem; /* 16px */
  --size-font-large: 1.125rem; /* 18px */
  --size-font-xlarge: 1.5rem; /* 24px */
  --size-font-xxlarge: 1.75rem; /* 28px */
  --size-font-xxxlarge: 2.375rem; /* 38px */
  --size-line-height-xlarge: 2.375rem; /* 38px */
  --size-line-height-large: 1.75rem; /* 28px */
  --size-line-height-medium: 1.5rem; /* 24px */
  --size-line-height-small: 1.25rem; /* 20px */
  --size-line-height-xsmall: 1.125rem; /* 18px */
  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-demi: 600;
  --font-weight-bold: 700;
  --font-weight-heavy: 900;
  --font-family-default: 'AvenirNextP2ForBBG', Arial, Helvetica, sans-serif;
  --font-family-accent: 'AvenirNextP2ForBBG', Arial, Helvetica, sans-serif;
  --font-family-monospace: RobotoMono;
  --baseline-0: 0rem;
  --baseline-1: 0.25rem;
  --baseline-2: 0.5rem;
  --baseline-3: 0.75rem;
  --baseline-4: 1rem;
  --baseline-5: 1.25rem;
  --baseline-6: 1.625rem;
  --baseline-7: 1.875rem;
  --baseline-8: 2rem;
  --baseline-9: 2.25rem;
  --baseline-10: 2.5rem;
  --baseline-12: 3rem;
  --baseline-14: 3.625rem;
  --baseline-18: 4.5rem;
  --baseline-20: 5rem;
  --baseline-24: 6rem;
  --baseline-36: 9rem;
  --baseline-40: 0.006rem;
  --baseline-48: 0.875rem;
  --baseline-60: 0.063rem;
  --baseline-75: 0.125rem;
  --baseline-90: -0.063rem;
  --baseline-196: 12.25rem;
  --baseline-296: 18.5rem;
  --baseline-300: 18.75rem;
  --baseline-2-5: 0.625rem;
  --baseline-3-5: 0.938rem;
  --baseline-4-5: 1.125rem;
  --baseline-5-5: 1.5rem;
  --baseline-8-5: 2.188rem;
  --baseline-s-5: 0.031rem;
  --baseline-s-7-5: 0.047rem;
  --baseline-n-75: -0.13rem;
  --visibility-hidden: hidden;
  --visibility-visible: visible;
  --text-transform-uppercase: uppercase;
  --text-transform-inherit: inherit;
}

/**
 * Do not edit directly
 * Generated on Sat, 11 Jun 2022 21:44:16 GMT
 */

.moonfish[data-theme='crescent'] {
  --color-text: #ffffff;
  --color-disabled-text: #787878;
  --color-link: #ffffff;
  --color-link-visited: #ffffff;
  --color-focus: #0073ff;
  --color-background: rgba(0, 0, 0, 0.01);
  --color-primary-text: #ffffff;
  --color-primary-hover: #2b8eff;
  --color-primary-action: #0062dd;
  --color-primary-active: #0051ba;
  --color-primary-disabled-background: #f0f0f0;
  --color-primary-disabled-text: #646464;
  --color-error-background: #fee6eb;
  --color-error-graphic: #c31834;
  --color-error-text: #c31834;
  --color-error-text-contrast: #ffffff;
  --color-error-hover: #fd2d4a;
  --color-success-background: #c5facf;
  --color-success-graphic: #00a223;
  --color-success-text: #00a223;
  --color-success-text-contrast: #000000;
  --color-success-hover: #35d655;
  --color-warning-background: #fdc8b7;
  --color-warning-graphic: #fa5a28;
  --color-warning-text: #d64d22;
  --color-warning-text-contrast: #000000;
  --color-warning-hover: #fb754c;
  --color-info-background: #cce3ff;
  --color-info-graphic: #2b8eff;
  --color-info-text: #0062dd;
  --color-info-text-contrast: #000000;
  --color-info-hover: #2b8eff;
  --color-progress-background: #f8e5fb;
  --color-progress-graphic: #c873ff;
  --color-progress-text: #c873ff;
  --color-progress-text-contrast: #000000;
  --color-progress-hover: #d18aff;
  --color-default-background: #bebebe;
  --color-default-graphic: #bebebe;
  --color-default-text: #1e1e1e;
  --color-default-text-contrast: #000000;
  --color-default-hover: #8c8c8c;
  --color-background-shades-softest: #f8f8f8;
  --color-background-shades-soft: #f0f0f0;
  --color-background-shades-medium: #f0f0f0;
  --color-background-shades-strong: #646464;
  --color-scrollbar-thumb: rgba(0, 0, 0, 0.4);
  --color-scrollbar-track: rgba(0, 0, 0, 0);
  --color-table-background: #ffffff;
  --color-table-background-hover: #e1e1e1;
  --color-table-border: #d7d7d7;
  --color-table-focus: #0073ff;
  --color-table-header-background: #ffffff;
  --color-table-header-background-hover: #e1e1e1;
  --color-table-highlight: #e1e1e1;
  --color-table-resize-handle: #232323;
  --color-table-resizing-cell-border: #bebebe;
  --color-table-selected-strip: #2b8eff;
  --color-table-sort-indicator-disabled: #bebebe;
  --color-table-sort-indicator-ghost: #8c8c8c;
  --color-table-text: #000000;
  --color-form-elements-border: #8c8c8c;
  --color-form-elements-active-border: #2b8eff;
  --color-form-elements-disabled-background: #e1e1e1;
  --color-form-elements-background: #000000;
  --color-form-elements-disabled-border: #e1e1e1;
  --color-form-elements-success: #00a223;
  --color-form-elements-warning: #db8922;
  --color-form-elements-error: #c31834;
  --color-button-primary-text: #ffffff;
  --color-button-primary-border: #0062dd;
  --color-button-primary-background: #0d9ddb;
  --color-button-primary-hover-text: #ffffff;
  --color-button-primary-hover-border: #2b8eff;
  --color-button-primary-hover-background: #0a80b3;
  --color-button-primary-active-text: #ffffff;
  --color-button-primary-active-border: #0051ba;
  --color-button-primary-active-background: #0051ba;
  --color-button-primary-disabled-text: #646464;
  --color-button-primary-disabled-border: #f0f0f0;
  --color-button-primary-disabled-background: #f0f0f0;
  --color-button-secondary-text: #ffffff;
  --color-button-secondary-border: #ffffff;
  --color-button-secondary-background: #ffffff;
  --color-button-secondary-hover-text: #232323;
  --color-button-secondary-hover-border: #232323;
  --color-button-secondary-hover-background: #232323;
  --color-button-secondary-active-text: #232323;
  --color-button-secondary-active-border: #232323;
  --color-button-secondary-active-background: #a5a5a5;
  --color-button-secondary-disabled-text: #646464;
  --color-button-secondary-disabled-border: #a5a5a5;
  --color-button-secondary-disabled-background: #ffffff;
  --color-button-tertiary-text: #232323;
  --color-button-tertiary-border: rgba(0, 0, 0, 0);
  --color-button-tertiary-background: rgba(0, 0, 0, 0);
  --color-button-tertiary-hover-text: #232323;
  --color-button-tertiary-hover-border: #e1e1e1;
  --color-button-tertiary-hover-background: #e1e1e1;
  --color-button-tertiary-active-text: #232323;
  --color-button-tertiary-active-border: #a5a5a5;
  --color-button-tertiary-active-background: #a5a5a5;
  --color-button-tertiary-disabled-text: #646464;
  --color-button-tertiary-disabled-border: rgba(0, 0, 0, 0);
  --color-button-tertiary-disabled-background: rgba(0, 0, 0, 0);
  --color-button-destruction-text: #ffffff;
  --color-button-destruction-border: #e51e3c;
  --color-button-destruction-background: #e51e3c;
  --color-button-destruction-hover-text: #ffffff;
  --color-button-destruction-hover-border: #fe415c;
  --color-button-destruction-hover-background: #fe415c;
  --color-button-destruction-active-text: #ffffff;
  --color-button-destruction-active-border: #c31834;
  --color-button-destruction-active-background: #c31834;
  --color-button-destruction-disabled-text: #a5a5a5;
  --color-button-destruction-disabled-border: #f0f0f0;
  --color-button-destruction-disabled-background: #f0f0f0;
  --color-card-hover-background: #ffffff;
  --color-date-picker-text: #000000;
  --color-date-picker-background: #ffffff;
  --color-date-picker-hover-background: #d7d7d7;
  --color-date-picker-pressed-background: #a5a5a5;
  --color-date-picker-selected-color: #2b8eff;
  --color-date-picker-selected-border: #000000;
  --color-dropdown-active-border: #2b8eff;
  --color-dropdown-border: #8c8c8c;
  --color-dropdown-background: #ffffff;
  --color-dropdown-disabled-background: #e1e1e1;
  --color-dropdown-item-highlight: #d7d7d7;
  --color-dropzone-active-border: #2b8eff;
  --color-dropzone-border: #8c8c8c;
  --color-dropzone-error-border: #e51e3c;
  --color-dropzone-hover-background: #cce3ff;
  --color-dropzone-hover-text: #0062dd;
  --color-dropzone-text: #000000;
  --color-pagination-focus-icon: #66abff;
  --color-progress-meter-empty-track: #f0f0f0;
  --color-progress-meter-active-track: #ffa028;
  --color-slider-empty-track: #d7d7d7;
  --color-slider-active-track: #0062dd;
  --color-slider-handle-background: #ffffff;
  --color-slider-handle-border: #2b8eff;
  --color-slider-disabled-handle-border: #646464;
  --color-split-button-primary-divider: #ffffff;
  --color-split-button-primary-disabled-divider: #ffffff;
  --color-split-button-secondary-divider: #232323;
  --color-split-button-secondary-disabled-divider: #a5a5a5;
  --color-split-button-tertiary-divider: #232323;
  --color-split-button-tertiary-disabled-divider: #a5a5a5;
  --color-stepper-normal: #232323;
  --color-stepper-border: #000000;
  --color-stepper-disabled: #a5a5a5;
  --color-stepper-hover: #232323;
  --color-stepper-active: #232323;
  --color-stepper-complete: #2b8eff;
  --color-switch-inactive-border: #646464;
  --color-switch-active-border: #646464;
  --color-switch-focus-border: #2b8eff;
  --color-switch-disabled-border: #a5a5a5;
  --color-switch-inactive-track: #ffffff;
  --color-switch-active-track: #ffffff;
  --color-switch-active: #0062dd;
  --color-switch-inactive: #232323;
  --color-switch-checkmark: #ffffff;
  --color-switch-inactive-hover: #e1e1e1;
  --color-switch-active-hover: #e1e1e1;
  --color-switch-disabled: #a5a5a5;
  --color-tab-font: #232323;
  --color-tab-focus: rgba(0, 0, 0, 0.12);
  --color-tab-focus-border: #0062dd;
  --color-tab-background: rgba(0, 0, 0, 0.06);
  --color-tab-border-bottom: rgba(255, 255, 255, 0);
  --color-tab-selected-bottom: #ffffff;
  --color-tab-wrapper: #646464;
  --color-tab-hover: rgba(0, 0, 0, 0.12);
  --color-tab-selected: #ffffff;
  --color-tab-selected-border: #646464;
  --color-tab-selected-border-top: #0062dd;
  --color-tab-pressed: rgba(0, 0, 0, 0.4);
  --color-tab-disabled: rgba(0, 0, 0, 0.06);
  --color-tab-disabled-font: #bebebe;
  --color-tab-disabled-border: rgba(255, 255, 255, 0);
  --color-tile-border: #d7d7d7;
  --color-tile-background: #ffffff;
  --color-tile-divider: #000000;
  --color-time-picker-background: #ffffff;
  --color-time-picker-selected-background: #e1e1e1;
  --color-time-picker-hover-background: #e1e1e1;
  --color-toggle-button-bar-background: #ffffff;
  --color-toggle-button-bar-border: #232323;
  --color-toggle-button-bar-text: #232323;
  --color-toggle-button-bar-hover-background: #e1e1e1;
  --color-toggle-button-bar-hover-border: #232323;
  --color-toggle-button-bar-hover-text: #232323;
  --color-toggle-button-bar-active-background: #a5a5a5;
  --color-toggle-button-bar-active-border: #232323;
  --color-toggle-button-bar-active-text: #232323;
  --color-toggle-button-bar-selected-background: #232323;
  --color-toggle-button-bar-selected-border: #232323;
  --color-toggle-button-bar-selected-text: #ffffff;
  --color-token-background: #d7d7d7;
  --color-token-progress-background: #f0f0f0;
  --color-token-active-background: #a5a5a5;
  --color-token-icon-focus-border: #2b8eff;
  --style-dropdown-border-radius: 0rem;
  --style-button-border-radius: 0rem;
  --style-button-text-transform: uppercase;
  --style-form-elements-border-radius: 0rem;
  --style-form-elements-required-asterisk: visible;
  --sizing-slider-border: 0rem;
  --sizing-slider-position-left: 0.125rem;
  --sizing-slider-position-bottom: 0.125rem;
  --sizing-tab-border-bottom: 0.063rem;
  --sizing-tab-disabled-border: 0.063rem;
}

@media screen and (color-index: 48) {
  /**
 * Do not edit directly
 * Generated on Sat, 11 Jun 2022 21:44:16 GMT
 */

  .moonfish:not([data-theme]),
  .moonfish[data-theme='crescent'] {
    --color-text: #f8f8f8;
    --color-disabled-text: #a5a5a5;
    --color-link: #f8f8f8;
    --color-link-visited: #bebebe;
    --color-background: #191919;
    --color-primary-text: #f8f8f8;
    --color-primary-hover: #2b8eff;
    --color-primary-action: #0062dd;
    --color-primary-active: #0051ba;
    --color-primary-disabled-background: #373737;
    --color-primary-disabled-text: #787878;
    --color-error-background: #ffa7af;
    --color-error-graphic: #fd2d4a;
    --color-error-text: #fd2d4a;
    --color-error-hover: #fe415c;
    --color-success-background: #50f06e;
    --color-warning-background: #fc9170;
    --color-warning-text: #fa5a28;
    --color-info-background: #99c7ff;
    --color-info-graphic: #0073ff;
    --color-progress-background: #daa2ff;
    --color-default-background: #646464;
    --color-default-graphic: #646464;
    --color-default-text: #1e1e1e;
    --color-default-text-contrast: #000000;
    --color-default-hover: #8c8c8c;
    --color-background-shades-softest: #1e1e1e;
    --color-background-shades-soft: #232323;
    --color-background-shades-medium: #373737;
    --color-background-shades-strong: #646464;
    --color-scrollbar-thumb: rgba(255, 255, 255, 0.4);
    --color-scrollbar-track: rgba(0, 0, 0, 0);
    --color-table-background: #1e1e1e;
    --color-table-background-hover: #505050;
    --color-table-border: #414141;
    --color-table-header-background: #000000;
    --color-table-header-background-hover: #505050;
    --color-table-highlight: #505050;
    --color-table-resize-handle: #d7d7d7;
    --color-table-resizing-cell-border: #646464;
    --color-table-sort-indicator-disabled: #646464;
    --color-table-sort-indicator-ghost: #8c8c8c;
    --color-table-text: #ffffff;
    --color-button-secondary-text: #e1e1e1;
    --color-button-secondary-border: #e1e1e1;
    --color-button-secondary-hover-text: #e1e1e1;
    --color-button-secondary-hover-border: #e1e1e1;
    --color-button-secondary-hover-background: #282828;
    --color-button-secondary-active-text: #e1e1e1;
    --color-button-secondary-active-border: #e1e1e1;
    --color-button-secondary-active-background: #414141;
    --color-button-secondary-disabled-text: #787878;
    --color-button-secondary-disabled-border: #787878;
    --color-button-tertiary-text: #e1e1e1;
    --color-button-tertiary-hover-text: #e1e1e1;
    --color-button-tertiary-hover-background: #282828;
    --color-button-tertiary-hover-border: #282828;
    --color-button-tertiary-active-text: #e1e1e1;
    --color-button-tertiary-active-border: #414141;
    --color-button-tertiary-active-background: #414141;
    --color-button-tertiary-disabled-text: #787878;
    --color-button-destruction-disabled-text: #646464;
    --color-button-destruction-disabled-border: #373737;
    --color-button-destruction-disabled-background: #373737;
    --color-card-hover-background: #1e1e1e;
    --color-date-picker-text: #d7d7d7;
    --color-date-picker-background: #232323;
    --color-date-picker-hover-background: #505050;
    --color-date-picker-pressed-background: #373737;
    --color-date-picker-selected-border: #ffffff;
    --color-dropzone-border: #8c8c8c;
    --color-dropzone-hover-background: #001f53;
    --color-dropzone-hover-text: #99c7ff;
    --color-dropzone-text: #ffffff;
    --color-progress-meter-empty-track: #505050;
    --color-slider-empty-track: #414141;
    --color-slider-active-track: #0062dd;
    --color-slider-handle-background: #ffffff;
    --color-slider-handle-border: #2b8eff;
    --color-slider-disabled-handle-border: #787878;
    --color-stepper-normal: #f8f8f8;
    --color-stepper-border: #f8f8f8;
    --color-stepper-disabled: #505050;
    --color-switch-inactive-border: #e1e1e1;
    --color-switch-active-border: #e1e1e1;
    --color-switch-disabled-border: #505050;
    --color-switch-track: #191919;
    --color-switch-active: #0073ff;
    --color-switch-inactive: #d7d7d7;
    --color-switch-hover: #373737;
    --color-switch-disabled: #505050;
    --color-tab-font: #ffffff;
    --color-tab-focus: rgba(255, 255, 255, 0.13);
    --color-tab-focus-border: #0062dd;
    --color-tab-background: rgba(255, 255, 255, 0.04);
    --color-tab-border-bottom: rgba(255, 255, 255, 0);
    --color-tab-selected-bottom: #000000;
    --color-tab-wrapper: #ffffff;
    --color-tab-hover: rgba(255, 255, 255, 0.13);
    --color-tab-selected: #000000;
    --color-tab-selected-border: #ffffff;
    --color-tab-selected-border-top: #0062dd;
    --color-tab-pressed: rgba(255, 255, 255, 0.32);
    --color-tab-disabled: rgba(255, 255, 255, 0.04);
    --color-tab-disabled-font: #bebebe;
    --color-tab-disabled-border: rgba(255, 255, 255, 0);
    --color-tile-border: #000000;
    --color-tile-background: #191919;
    --color-tile-divider: #ffffff;
    --color-time-picker-background: #232323;
    --color-time-picker-selected-background: #646464;
    --color-time-picker-hover-background: #646464;
    --color-toggle-button-bar-background: #191919;
    --color-toggle-button-bar-border: #e1e1e1;
    --color-toggle-button-bar-text: #e1e1e1;
    --color-toggle-button-bar-hover-background: #282828;
    --color-toggle-button-bar-hover-border: #e1e1e1;
    --color-toggle-button-bar-hover-text: #e1e1e1;
    --color-toggle-button-bar-active-background: #414141;
    --color-toggle-button-bar-active-border: #e1e1e1;
    --color-toggle-button-bar-active-text: #e1e1e1;
    --color-toggle-button-bar-selected-background: #f8f8f8;
    --color-toggle-button-bar-selected-border: #f8f8f8;
    --color-toggle-button-bar-selected-text: #282828;
    --color-token-background: #373737;
    --color-token-progress-background: #232323;
    --color-token-active-background: #8c8c8c;
    --color-contextual-message-info-graphic: #282828;
    --color-contextual-message-info-text-contrast: #ffffff;
    --color-contextual-message-info-hover: #414141;
    --color-contextual-message-warning-graphic: #471a0b;
    --color-contextual-message-warning-text-contrast: #ffffff;
    --color-contextual-message-warning-hover: #6b2711;
  }
}

@media screen and (color: 48842621) {
  /**
 * Do not edit directly
 * Generated on Sat, 11 Jun 2022 21:44:16 GMT
 */

  .moonfish:not([data-theme]),
  .moonfish[data-theme='crescent'] {
    --color-text: #f8f8f8;
    --color-disabled-text: #a5a5a5;
    --color-link: #f8f8f8;
    --color-link-visited: #bebebe;
    --color-background: #191919;
    --color-primary-text: #f8f8f8;
    --color-primary-hover: #2b8eff;
    --color-primary-action: #0062dd;
    --color-primary-active: #0051ba;
    --color-primary-disabled-background: #373737;
    --color-primary-disabled-text: #787878;
    --color-error-background: #ffa7af;
    --color-error-graphic: #fd2d4a;
    --color-error-text: #fd2d4a;
    --color-error-hover: #fe415c;
    --color-success-background: #50f06e;
    --color-warning-background: #fc9170;
    --color-warning-text: #fa5a28;
    --color-info-background: #99c7ff;
    --color-info-graphic: #0073ff;
    --color-progress-background: #daa2ff;
    --color-default-background: #646464;
    --color-default-graphic: #646464;
    --color-default-text: #1e1e1e;
    --color-default-text-contrast: #000000;
    --color-default-hover: #8c8c8c;
    --color-background-shades-softest: #1e1e1e;
    --color-background-shades-soft: #232323;
    --color-background-shades-medium: #373737;
    --color-background-shades-strong: #646464;
    --color-scrollbar-thumb: rgba(255, 255, 255, 0.4);
    --color-scrollbar-track: rgba(0, 0, 0, 0);
    --color-table-background: #1e1e1e;
    --color-table-background-hover: #505050;
    --color-table-border: #414141;
    --color-table-header-background: #000000;
    --color-table-header-background-hover: #505050;
    --color-table-highlight: #505050;
    --color-table-resize-handle: #d7d7d7;
    --color-table-resizing-cell-border: #646464;
    --color-table-sort-indicator-disabled: #646464;
    --color-table-sort-indicator-ghost: #8c8c8c;
    --color-table-text: #ffffff;
    --color-button-secondary-text: #e1e1e1;
    --color-button-secondary-border: #e1e1e1;
    --color-button-secondary-hover-text: #e1e1e1;
    --color-button-secondary-hover-border: #e1e1e1;
    --color-button-secondary-hover-background: #282828;
    --color-button-secondary-active-text: #e1e1e1;
    --color-button-secondary-active-border: #e1e1e1;
    --color-button-secondary-active-background: #414141;
    --color-button-secondary-disabled-text: #787878;
    --color-button-secondary-disabled-border: #787878;
    --color-button-tertiary-text: #e1e1e1;
    --color-button-tertiary-hover-text: #e1e1e1;
    --color-button-tertiary-hover-background: #282828;
    --color-button-tertiary-hover-border: #282828;
    --color-button-tertiary-active-text: #e1e1e1;
    --color-button-tertiary-active-border: #414141;
    --color-button-tertiary-active-background: #414141;
    --color-button-tertiary-disabled-text: #787878;
    --color-button-destruction-disabled-text: #646464;
    --color-button-destruction-disabled-border: #373737;
    --color-button-destruction-disabled-background: #373737;
    --color-card-hover-background: #1e1e1e;
    --color-date-picker-text: #d7d7d7;
    --color-date-picker-background: #232323;
    --color-date-picker-hover-background: #505050;
    --color-date-picker-pressed-background: #373737;
    --color-date-picker-selected-border: #ffffff;
    --color-dropzone-border: #8c8c8c;
    --color-dropzone-hover-background: #001f53;
    --color-dropzone-hover-text: #99c7ff;
    --color-dropzone-text: #ffffff;
    --color-progress-meter-empty-track: #505050;
    --color-slider-empty-track: #414141;
    --color-slider-active-track: #0062dd;
    --color-slider-handle-background: #ffffff;
    --color-slider-handle-border: #2b8eff;
    --color-slider-disabled-handle-border: #787878;
    --color-stepper-normal: #f8f8f8;
    --color-stepper-border: #f8f8f8;
    --color-stepper-disabled: #505050;
    --color-switch-inactive-border: #e1e1e1;
    --color-switch-active-border: #e1e1e1;
    --color-switch-disabled-border: #505050;
    --color-switch-track: #191919;
    --color-switch-active: #0073ff;
    --color-switch-inactive: #d7d7d7;
    --color-switch-hover: #373737;
    --color-switch-disabled: #505050;
    --color-tab-font: #ffffff;
    --color-tab-focus: rgba(255, 255, 255, 0.13);
    --color-tab-focus-border: #0062dd;
    --color-tab-background: rgba(255, 255, 255, 0.04);
    --color-tab-border-bottom: rgba(255, 255, 255, 0);
    --color-tab-selected-bottom: #000000;
    --color-tab-wrapper: #ffffff;
    --color-tab-hover: rgba(255, 255, 255, 0.13);
    --color-tab-selected: #000000;
    --color-tab-selected-border: #ffffff;
    --color-tab-selected-border-top: #0062dd;
    --color-tab-pressed: rgba(255, 255, 255, 0.32);
    --color-tab-disabled: rgba(255, 255, 255, 0.04);
    --color-tab-disabled-font: #bebebe;
    --color-tab-disabled-border: rgba(255, 255, 255, 0);
    --color-tile-border: #000000;
    --color-tile-background: #191919;
    --color-tile-divider: #ffffff;
    --color-time-picker-background: #232323;
    --color-time-picker-selected-background: #646464;
    --color-time-picker-hover-background: #646464;
    --color-toggle-button-bar-background: #191919;
    --color-toggle-button-bar-border: #e1e1e1;
    --color-toggle-button-bar-text: #e1e1e1;
    --color-toggle-button-bar-hover-background: #282828;
    --color-toggle-button-bar-hover-border: #e1e1e1;
    --color-toggle-button-bar-hover-text: #e1e1e1;
    --color-toggle-button-bar-active-background: #414141;
    --color-toggle-button-bar-active-border: #e1e1e1;
    --color-toggle-button-bar-active-text: #e1e1e1;
    --color-toggle-button-bar-selected-background: #f8f8f8;
    --color-toggle-button-bar-selected-border: #f8f8f8;
    --color-toggle-button-bar-selected-text: #282828;
    --color-token-background: #373737;
    --color-token-progress-background: #232323;
    --color-token-active-background: #8c8c8c;
    --color-contextual-message-info-graphic: #282828;
    --color-contextual-message-info-text-contrast: #ffffff;
    --color-contextual-message-info-hover: #414141;
    --color-contextual-message-warning-graphic: #471a0b;
    --color-contextual-message-warning-text-contrast: #ffffff;
    --color-contextual-message-warning-hover: #6b2711;
  }
}

@media screen and (prefers-color-scheme: dark) {
  /**
 * Do not edit directly
 * Generated on Sat, 11 Jun 2022 21:44:16 GMT
 */

  .moonfish:not([data-theme]):not([data-color-scheme='light']),
  .moonfish[data-theme='crescent']:not([data-color-scheme='light']) {
    --color-text: #f8f8f8;
    --color-disabled-text: #a5a5a5;
    --color-link: #f8f8f8;
    --color-link-visited: #bebebe;
    --color-background: #191919;
    --color-primary-text: #f8f8f8;
    --color-primary-hover: #2b8eff;
    --color-primary-action: #0062dd;
    --color-primary-active: #0051ba;
    --color-primary-disabled-background: #373737;
    --color-primary-disabled-text: #787878;
    --color-error-background: #ffa7af;
    --color-error-graphic: #fd2d4a;
    --color-error-text: #fd2d4a;
    --color-error-hover: #fe415c;
    --color-success-background: #50f06e;
    --color-warning-background: #fc9170;
    --color-warning-text: #fa5a28;
    --color-info-background: #99c7ff;
    --color-info-graphic: #0073ff;
    --color-progress-background: #daa2ff;
    --color-default-background: #646464;
    --color-default-graphic: #646464;
    --color-default-text: #1e1e1e;
    --color-default-text-contrast: #000000;
    --color-default-hover: #8c8c8c;
    --color-background-shades-softest: #1e1e1e;
    --color-background-shades-soft: #232323;
    --color-background-shades-medium: #373737;
    --color-background-shades-strong: #646464;
    --color-scrollbar-thumb: rgba(255, 255, 255, 0.4);
    --color-scrollbar-track: rgba(0, 0, 0, 0);
    --color-table-background: #1e1e1e;
    --color-table-background-hover: #505050;
    --color-table-border: #414141;
    --color-table-header-background: #000000;
    --color-table-header-background-hover: #505050;
    --color-table-highlight: #505050;
    --color-table-resize-handle: #d7d7d7;
    --color-table-resizing-cell-border: #646464;
    --color-table-sort-indicator-disabled: #646464;
    --color-table-sort-indicator-ghost: #8c8c8c;
    --color-table-text: #ffffff;
    --color-button-secondary-text: #e1e1e1;
    --color-button-secondary-border: #e1e1e1;
    --color-button-secondary-hover-text: #e1e1e1;
    --color-button-secondary-hover-border: #e1e1e1;
    --color-button-secondary-hover-background: #282828;
    --color-button-secondary-active-text: #e1e1e1;
    --color-button-secondary-active-border: #e1e1e1;
    --color-button-secondary-active-background: #414141;
    --color-button-secondary-disabled-text: #787878;
    --color-button-secondary-disabled-border: #787878;
    --color-button-tertiary-text: #e1e1e1;
    --color-button-tertiary-hover-text: #e1e1e1;
    --color-button-tertiary-hover-background: #282828;
    --color-button-tertiary-hover-border: #282828;
    --color-button-tertiary-active-text: #e1e1e1;
    --color-button-tertiary-active-border: #414141;
    --color-button-tertiary-active-background: #414141;
    --color-button-tertiary-disabled-text: #787878;
    --color-button-destruction-disabled-text: #646464;
    --color-button-destruction-disabled-border: #373737;
    --color-button-destruction-disabled-background: #373737;
    --color-card-hover-background: #1e1e1e;
    --color-date-picker-text: #d7d7d7;
    --color-date-picker-background: #232323;
    --color-date-picker-hover-background: #505050;
    --color-date-picker-pressed-background: #373737;
    --color-date-picker-selected-border: #ffffff;
    --color-dropzone-border: #8c8c8c;
    --color-dropzone-hover-background: #001f53;
    --color-dropzone-hover-text: #99c7ff;
    --color-dropzone-text: #ffffff;
    --color-progress-meter-empty-track: #505050;
    --color-slider-empty-track: #414141;
    --color-slider-active-track: #0062dd;
    --color-slider-handle-background: #ffffff;
    --color-slider-handle-border: #2b8eff;
    --color-slider-disabled-handle-border: #787878;
    --color-stepper-normal: #f8f8f8;
    --color-stepper-border: #f8f8f8;
    --color-stepper-disabled: #505050;
    --color-switch-inactive-border: #e1e1e1;
    --color-switch-active-border: #e1e1e1;
    --color-switch-disabled-border: #505050;
    --color-switch-track: #191919;
    --color-switch-active: #0073ff;
    --color-switch-inactive: #d7d7d7;
    --color-switch-hover: #373737;
    --color-switch-disabled: #505050;
    --color-tab-font: #ffffff;
    --color-tab-focus: rgba(255, 255, 255, 0.13);
    --color-tab-focus-border: #0062dd;
    --color-tab-background: rgba(255, 255, 255, 0.04);
    --color-tab-border-bottom: rgba(255, 255, 255, 0);
    --color-tab-selected-bottom: #000000;
    --color-tab-wrapper: #ffffff;
    --color-tab-hover: rgba(255, 255, 255, 0.13);
    --color-tab-selected: #000000;
    --color-tab-selected-border: #ffffff;
    --color-tab-selected-border-top: #0062dd;
    --color-tab-pressed: rgba(255, 255, 255, 0.32);
    --color-tab-disabled: rgba(255, 255, 255, 0.04);
    --color-tab-disabled-font: #bebebe;
    --color-tab-disabled-border: rgba(255, 255, 255, 0);
    --color-tile-border: #000000;
    --color-tile-background: #191919;
    --color-tile-divider: #ffffff;
    --color-time-picker-background: #232323;
    --color-time-picker-selected-background: #646464;
    --color-time-picker-hover-background: #646464;
    --color-toggle-button-bar-background: #191919;
    --color-toggle-button-bar-border: #e1e1e1;
    --color-toggle-button-bar-text: #e1e1e1;
    --color-toggle-button-bar-hover-background: #282828;
    --color-toggle-button-bar-hover-border: #e1e1e1;
    --color-toggle-button-bar-hover-text: #e1e1e1;
    --color-toggle-button-bar-active-background: #414141;
    --color-toggle-button-bar-active-border: #e1e1e1;
    --color-toggle-button-bar-active-text: #e1e1e1;
    --color-toggle-button-bar-selected-background: #f8f8f8;
    --color-toggle-button-bar-selected-border: #f8f8f8;
    --color-toggle-button-bar-selected-text: #282828;
    --color-token-background: #373737;
    --color-token-progress-background: #232323;
    --color-token-active-background: #8c8c8c;
    --color-contextual-message-info-graphic: #282828;
    --color-contextual-message-info-text-contrast: #ffffff;
    --color-contextual-message-info-hover: #414141;
    --color-contextual-message-warning-graphic: #471a0b;
    --color-contextual-message-warning-text-contrast: #ffffff;
    --color-contextual-message-warning-hover: #6b2711;
  }
}

@media screen {
  /**
 * Do not edit directly
 * Generated on Sat, 11 Jun 2022 21:44:16 GMT
 */

  .moonfish:not([data-theme])[data-color-scheme='dark'],
  .moonfish[data-theme='crescent'][data-color-scheme='dark'] {
    --color-text: #f8f8f8;
    --color-disabled-text: #a5a5a5;
    --color-link: #f8f8f8;
    --color-link-visited: #bebebe;
    --color-background: #191919;
    --color-primary-text: #f8f8f8;
    --color-primary-hover: #2b8eff;
    --color-primary-action: #0062dd;
    --color-primary-active: #0051ba;
    --color-primary-disabled-background: #373737;
    --color-primary-disabled-text: #787878;
    --color-error-background: #ffa7af;
    --color-error-graphic: #fd2d4a;
    --color-error-text: #fd2d4a;
    --color-error-hover: #fe415c;
    --color-success-background: #50f06e;
    --color-warning-background: #fc9170;
    --color-warning-text: #fa5a28;
    --color-info-background: #99c7ff;
    --color-info-graphic: #0073ff;
    --color-progress-background: #daa2ff;
    --color-default-background: #646464;
    --color-default-graphic: #646464;
    --color-default-text: #1e1e1e;
    --color-default-text-contrast: #000000;
    --color-default-hover: #8c8c8c;
    --color-background-shades-softest: #1e1e1e;
    --color-background-shades-soft: #232323;
    --color-background-shades-medium: #373737;
    --color-background-shades-strong: #646464;
    --color-scrollbar-thumb: rgba(255, 255, 255, 0.4);
    --color-scrollbar-track: rgba(0, 0, 0, 0);
    --color-table-background: #1e1e1e;
    --color-table-background-hover: #505050;
    --color-table-border: #414141;
    --color-table-header-background: #000000;
    --color-table-header-background-hover: #505050;
    --color-table-highlight: #505050;
    --color-table-resize-handle: #d7d7d7;
    --color-table-resizing-cell-border: #646464;
    --color-table-sort-indicator-disabled: #646464;
    --color-table-sort-indicator-ghost: #8c8c8c;
    --color-table-text: #ffffff;
    --color-button-secondary-text: #e1e1e1;
    --color-button-secondary-border: #e1e1e1;
    --color-button-secondary-hover-text: #e1e1e1;
    --color-button-secondary-hover-border: #e1e1e1;
    --color-button-secondary-hover-background: #282828;
    --color-button-secondary-active-text: #e1e1e1;
    --color-button-secondary-active-border: #e1e1e1;
    --color-button-secondary-active-background: #414141;
    --color-button-secondary-disabled-text: #787878;
    --color-button-secondary-disabled-border: #787878;
    --color-button-tertiary-text: #e1e1e1;
    --color-button-tertiary-hover-text: #e1e1e1;
    --color-button-tertiary-hover-background: #282828;
    --color-button-tertiary-hover-border: #282828;
    --color-button-tertiary-active-text: #e1e1e1;
    --color-button-tertiary-active-border: #414141;
    --color-button-tertiary-active-background: #414141;
    --color-button-tertiary-disabled-text: #787878;
    --color-button-destruction-disabled-text: #646464;
    --color-button-destruction-disabled-border: #373737;
    --color-button-destruction-disabled-background: #373737;
    --color-card-hover-background: #1e1e1e;
    --color-date-picker-text: #d7d7d7;
    --color-date-picker-background: #232323;
    --color-date-picker-hover-background: #505050;
    --color-date-picker-pressed-background: #373737;
    --color-date-picker-selected-border: #ffffff;
    --color-dropzone-border: #8c8c8c;
    --color-dropzone-hover-background: #001f53;
    --color-dropzone-hover-text: #99c7ff;
    --color-dropzone-text: #ffffff;
    --color-progress-meter-empty-track: #505050;
    --color-slider-empty-track: #414141;
    --color-slider-active-track: #0062dd;
    --color-slider-handle-background: #ffffff;
    --color-slider-handle-border: #2b8eff;
    --color-slider-disabled-handle-border: #787878;
    --color-stepper-normal: #f8f8f8;
    --color-stepper-border: #f8f8f8;
    --color-stepper-disabled: #505050;
    --color-switch-inactive-border: #e1e1e1;
    --color-switch-active-border: #e1e1e1;
    --color-switch-disabled-border: #505050;
    --color-switch-track: #191919;
    --color-switch-active: #0073ff;
    --color-switch-inactive: #d7d7d7;
    --color-switch-hover: #373737;
    --color-switch-disabled: #505050;
    --color-tab-font: #ffffff;
    --color-tab-focus: rgba(255, 255, 255, 0.13);
    --color-tab-focus-border: #0062dd;
    --color-tab-background: rgba(255, 255, 255, 0.04);
    --color-tab-border-bottom: rgba(255, 255, 255, 0);
    --color-tab-selected-bottom: #000000;
    --color-tab-wrapper: #ffffff;
    --color-tab-hover: rgba(255, 255, 255, 0.13);
    --color-tab-selected: #000000;
    --color-tab-selected-border: #ffffff;
    --color-tab-selected-border-top: #0062dd;
    --color-tab-pressed: rgba(255, 255, 255, 0.32);
    --color-tab-disabled: rgba(255, 255, 255, 0.04);
    --color-tab-disabled-font: #bebebe;
    --color-tab-disabled-border: rgba(255, 255, 255, 0);
    --color-tile-border: #000000;
    --color-tile-background: #191919;
    --color-tile-divider: #ffffff;
    --color-time-picker-background: #232323;
    --color-time-picker-selected-background: #646464;
    --color-time-picker-hover-background: #646464;
    --color-toggle-button-bar-background: #191919;
    --color-toggle-button-bar-border: #e1e1e1;
    --color-toggle-button-bar-text: #e1e1e1;
    --color-toggle-button-bar-hover-background: #282828;
    --color-toggle-button-bar-hover-border: #e1e1e1;
    --color-toggle-button-bar-hover-text: #e1e1e1;
    --color-toggle-button-bar-active-background: #414141;
    --color-toggle-button-bar-active-border: #e1e1e1;
    --color-toggle-button-bar-active-text: #e1e1e1;
    --color-toggle-button-bar-selected-background: #f8f8f8;
    --color-toggle-button-bar-selected-border: #f8f8f8;
    --color-toggle-button-bar-selected-text: #282828;
    --color-token-background: #373737;
    --color-token-progress-background: #232323;
    --color-token-active-background: #8c8c8c;
    --color-contextual-message-info-graphic: #282828;
    --color-contextual-message-info-text-contrast: #ffffff;
    --color-contextual-message-info-hover: #414141;
    --color-contextual-message-warning-graphic: #471a0b;
    --color-contextual-message-warning-text-contrast: #ffffff;
    --color-contextual-message-warning-hover: #6b2711;
  }
}

/*
Define font-family AvenirNextP2ForBBG

Each weight has both normal and italic styles

Font family weights:
Heavy  - 900
Bold   - 700
Demi   - 600
Medium - 500
Normal - 400
Light  - 300
Thin   - 100
*/

/* AvenirNextP2ForBBG-Heavy*/

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 900;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Heavy-5b0822db83.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Heavy-3b108864bd.woff') format('woff');
}

/* AvenirNextP2ForBBG-HeavyItalic */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 900;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-HeavyItalic-38cab08278.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-HeavyItalic-ad5430020f.woff') format('woff');
}

/* AvenirNextP2ForBBG-Bold */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 700;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Bold-848b534204.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Bold-845494278e.woff') format('woff');
}

/* AvenirNextP2ForBBG-BoldItalic */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 700;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-BoldItalic-f577e39577.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-BoldItalic-860a65428e.woff') format('woff');
}

/* AvenirNextP2ForBBG-Demi */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 600;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Demi-d3cb04a057.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Demi-f362a7c4ad.woff') format('woff');
}

/* AvenirNextP2ForBBG-DemiItalic */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 600;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-DemiItalic-ce112bb955.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-DemiItalic-166b289109.woff') format('woff');
}

/* AvenirNextP2ForBBG-Medium */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 500;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Medium-dc5d08072d.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Medium-72dcf6a6c2.woff') format('woff');
}

/* AvenirNextP2ForBBG-MediumItalic */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 500;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-MediumItalic-8a13467cd3.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-MediumItalic-71db2048c8.woff') format('woff');
}

/* AvenirNextP2ForBBG-Regular */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 400;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Regular-517a851989.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Regular-80b65cbe3a.woff') format('woff');
}

/* AvenirNextP2ForBBG-Italic */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 400;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Italic-7107f3ab96.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Italic-226936a4cb.woff') format('woff');
}

/* AvenirNextP2ForBBG-Light */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 300;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Light-5175406f53.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Light-ba85d34cc2.woff') format('woff');
}

/* AvenirNextP2ForBBG-LightItalic */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 300;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-LightItalic-f57090c0e2.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-LightItalic-fe355e220d.woff') format('woff');
}

/* AvenirNextP2ForBBG-Thin */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 100;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Thin-a559885f35.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-Thin-2f48f00020.woff') format('woff');
}

/* AvenirNextP2ForBBG-ThinItalic */

@font-face {
  font-family: 'AvenirNextP2ForBBG';
  font-display: fallback;
  font-weight: 100;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-ThinItalic-b8376f71a8.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-ThinItalic-3792ff2042.woff') format('woff');
}

/* Roboto Monospace Font for Code Blocks and Inline Code */

/* RobotoMono-Bold */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 700;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-BoldItalic-b8868a8a86.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-BoldItalic-c363eb5209.woff') format('woff');
}

/* RobotoMono-BoldItalic */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 700;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-BoldItalic-f577e39577.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/AvenirNextP2ForBBG-BoldItalic-860a65428e.woff') format('woff');
}

/* RobotoMono-Medium */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 500;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Medium-f2563bb64f.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Medium-210d82da68.woff') format('woff');
}

/* RobotoMono-MediumItalic */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 500;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-MediumItalic-f543e48a0d.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-MediumItalic-3ffa114322.woff') format('woff');
}

/* RobotoMono-Regular */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 400;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Regular-9b83813218.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Regular-62db283fd7.woff') format('woff');
}

/* RobotoMono-Italic */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 400;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Italic-231d18af59.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Italic-ada1a06cff.woff') format('woff');
}

/* RobotoMono-Light */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 300;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Light-e5e77c869b.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Light-7b9d8444f2.woff') format('woff');
}

/* RobotoMono-LightItalic */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 300;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-LightItalic-3def77df5a.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-LightItalic-286a84c78c.woff') format('woff');
}

/* RobotoMono-Thin */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 100;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Thin-e15b1b8579.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-Thin-845ca1f2eb.woff') format('woff');
}

/* RobotoMono-ThinItalic */

@font-face {
  font-family: 'RobotoMono';
  font-display: fallback;
  font-weight: 100;
  font-style: italic;
  src:
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-ThinItalic-5f877bbef7.woff2') format('woff2'),
    url('https://assets.bwbx.io/s3/fontservice/fonts/RobotoMono-ThinItalic-c7f0e5e612.woff') format('woff');
}
