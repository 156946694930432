.wkp-expression-generator-heading-container {
  padding-top: 5px;
  padding-bottom: 10px;
  img {
    fill: black;
  }
  label {
    font-weight: normal;
  }
}
.wkp-expression-generator-example {
  font-size: 14px;
}
.expression-generator-summary-disclaimer {
  font-size: 12px;
  font-style: italic;
  margin: 10px 0;
}

.text-error {
  color: #e61e3c;
}
.wkp-ai-gradient-container {
  margin-top: 10px;
  border: 1px solid #d7d7d7;
  padding: 10px 15px;
  img {
    float: left;
    margin: 0px 5px 0px 0px;
  }
  p {
    padding-top: 2px;
    text-align: justify;
  }
}
.wkp-ai-gradient {
  height: 4px;
  background: linear-gradient(0.25turn, #00d2b3, #0d9ddb, #8a5db5);
  position: relative;
  top: -10px;
  margin: 0 -15px;
}
.wkp-ai-modal-container {
  margin-top: 5px;
}
.wkp-ai-describe-button {
  color: #1c82ff;
  font-weight: 500;
}
.wkp-ai-describe-button:hover {
  text-decoration: underline;
}
.wkp-ai-describe-alt-text {
  float: left;
  padding-right: 6px;
}
.wkp-ai-example-ul {
  padding: 0px 0px 0px 20px;
}

.wkp-ai-button-container {
  margin-top: 10px;
  display: flex;
}
