.m-button {
  display: block;
  width: 100%;
  min-width: 40px;
  font-size: var(--size-font-medium);
  background: var(--crescent-transparent);
  color: var(--crescent-black);
  border-radius: var(--style-button-border-radius);
  padding: var(--baseline-5) var(--baseline-0);
  height: var(--baseline-10);
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.m-button.primary {
  background: var(--color-button-primary-background);
  color: var(--color-button-primary-text);
}
.m-button.primary:hover {
  background: var(--color-button-primary-hover-background);
}
.m-button.primary:active {
  background: var(--color-button-primary-active-background);
}
.m-button.primary.disabled {
  background: var(--color-button-primary-disabled-background);
  color: var(--color-button-primary-disabled-text);
}
.m-button.secondary {
  background: var(--crescent-transparent);
  color: var(--color-button-secondary-text);
  border: 1px solid var(--color-button-secondary-border);
  box-sizing: border-box;
}
.m-button.secondary:hover {
  background: var(--color-button-secondary-hover-background);
}
.m-button.secondary:active {
  background: var(--color-button-secondary-active-background);
}
.m-button.secondary.disabled {
  color: var(--color-button-secondary-disabled-text);
  border-color: var(--color-button-secondary-disabled-border);
  background: var(--color-button-secondary-disabled-background);
}
.m-button.secondary.disabled:hover {
  color: var(--color-button-secondary-disabled-text);
  background: var(--color-button-secondary-disabled-background);
  border-color: var(--color-button-secondary-disabled-border);
}
.m-button.tertiary {
  background: var(--color-button-tertiary-background);
  color: var(--color-button-tertiary-text);
}
.m-button.tertiary:hover {
  background: var(--color-button-tertiary-hover-background);
}
.m-button.tertiary:active {
  background: var(--color-button-tertiary-active-background);
}
.m-button.tertiary.disabled {
  background: var(--color-button-tertiary-disabled-background);
  color: var(--color-button-tertiary-disabled-text);
}
.m-button.destructive {
  background: var(--color-button-destruction-background);
  color: var(--color-button-destruction-text);
}
.m-button.destructive:hover {
  background: var(--color-button-destruction-hover-background);
}
.m-button.destructive:active {
  background: var(--color-button-destruction-active-background);
}
.m-button.destructive.disabled {
  background: var(--color-button-destruction-disabled-background);
  color: var(--color-button-destruction-disabled-text);
}
.m-button.destructive.disabled:hover {
  background: var(--color-button-destruction-disabled-background);
}
.m-button.featureLabel {
  fill: currentcolor;
  height: var(--crescent-feature-label-height);
  border-width: var(--crescent-feature-label-border-width);
  padding: var(--crescent-feature-label-padding);
  line-height: var(--crescent-feature-label-line-height);
  font-size: var(--crescent-feature-label-font-size);
  font-weight: 600;
  letter-spacing: var(--crescent-feature-label-letter-spacing);
  text-align: center;
  color: var(--crescent-feature-label-color);
  background-color: var(--crescent-feature-label-background-color);
  border-color: var(--crescent-feature-label-border-color);
}
.m-button.featureLabel:hover {
  background: var(--crescent-feature-label-hover-background);
  color: var(--crescent-feature-label-hover-color);
}
.m-button.featureLabel:active {
  background: var(--crescent-feature-label-active-background);
  color: var(--crescent-feature-label-active-color);
}
.m-button.featureIcon {
  width: var(--crescent-feature-icon-width);
  height: var(--crescent-feature-icon-height);
  padding: var(--crescent-feature-icon-padding);
  margin: var(--crescent-feature-icon-margin);
  height: 100%;
  color: var(--crescent-feature-icon-color);
  background-color: var(--crescent-feature-icon-background-color);
  border-color: var(--crescent-feature-icon-border-color);
}
.m-button.featureIcon:hover {
  background: var(--crescent-feature-icon-hover-background);
  color: var(--crescent-feature-icon-hover-color);
}
.m-button.featureIcon:active {
  background: var(--crescent-feature-icon-active-background);
  color: var(--crescent-feature-icon-active-color);
}

a.m-button {
  text-decoration: none;
  margin: 10px;
}
a.m-button:visited {
  color: var(--color-button-primary-text);
}
a.m-button.secondary:visited {
  color: var(--color-button-secondary-text);
}
