.wkp-inspector-container,
.wkp-inspector-container-sidebar {
  position: absolute;
  top: -50px;
  right: 8px;
  bottom: 0px;
  width: 484px;
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 2;
  box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.15);

  h5 {
    font-size: 17px;
    color: lighten(#333, 30%);
    font-weight: lighter;
    border-bottom: 1px solid #e3e3e3;
    padding: 4px 0;
  }

  .help-icon {
    margin-left: 4px;
    color: #0073ff;
  }

  h4.wkp-inspector-title {
    display: flex;
    align-items: center;
    background-color: #d7d7d7;
    padding: 9px 20px;
    margin: 0;
    font-weight: bold;
    height: 40px;

    .wkp-inspector-close {
      display: flex;
      align-items: center;
      margin-left: auto;
      order: 2;
      cursor: pointer;
      border: none;
      background: none;
    }
  }

  code {
    padding: 12px 16px;
    background: #eee;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
    color: inherit;

    &:last-child {
      margin: 0;
    }
  }

  .wkp-inspector-body {
    padding: 14px 20px 0;
    flex-shrink: 1;
    overflow: auto;

    &.wkp-full-height {
      flex-grow: 1;
    }

    .wkp-step-name {
      margin-bottom: 20px;
    }

    .wkp-type-container {
      margin-top: 17px;
    }

    .wkp-type-items {
      margin-top: 15px;
      margin-left: 25px;
    }

    .wkp-column-items {
      margin-left: 15px;
      padding-bottom: 5px;
    }
  }

  .wkp-inspector-preview {
    flex-grow: 1;
    padding: 20px;

    @at-root #root .wkp-inspector-container .wkp-inspector-preview-btn {
      display: block;
      font-weight: bold;
      width: 100%;
    }

    .wkp-inspector-help-text {
      margin-top: 5px;
      text-align: center;
      color: #8c8c8c;
      font-size: 12px;
    }
  }

  .wkp-inspector-footer {
    padding: 20px;

    .collapse:last-child {
      .alert {
        margin-bottom: 0;
      }
    }
  }

  .wkp-inspector-warning {
    margin-top: 20px;
  }

  .wkp-fields-list {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 14px;

    th,
    td {
      padding: 6px 12px; // as for inputs

      &.wkp-no-paddings {
        padding: 0;
      }
    }

    tr {
      border-bottom: 1px solid #e3e3e3;
    }

    :last-child {
      border-bottom: none;
    }

    .bbui-input {
      &.bt-input {
        &:not(:focus):not(.has-error) {
          border-color: transparent;

          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }

    .form-control,
    .bt-select__control {
      // not focused, no errors
      &:not(:focus):not(.bt-select__control--is-focused):not(.j3Tk-c3116) {
        border-color: transparent;
      }

      // not focused
      &:not(:focus):not(.bt-select__control--is-focused) {
        &:hover {
          background: #f2f2f2;
        }
      }
    }
  }

  .wkp-cursor-default {
    cursor: default;
  }

  .wkp-btn-container {
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
      background: #d7d7d7;
    }

    &:active {
      background: #bebebe;
    }
  }
  .wkp-dropdown {
    display: grid;
    place-content: center;
    height: 40px;
    background: none;
    width: 100%;
    outline: none;

    &:hover {
      background: #e3e3e3;
    }

    &:active {
      background: #e3e3e3;
    }

    &:focus {
      background: #e3e3e3;
      border: none;
      outline: none;
    }
  }

  .wkp-dropdown-disabled {
    pointer-events: none;
    background-color: white;
  }

  .wkp-menu-item {
    cursor: pointer;
  }
  .wkp-menu-item-disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  .wkp-line-break {
    margin: 0 8%;
  }

  .wkp-data-type {
    background: none;
    border: none;
    padding: 6px 12px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%;
    text-align: left;
    &:hover {
      background: #e3e3e3;
    }
  }

  .wkp-data-type-clean {
    background: none;
    border: none;
    padding: 6px 12px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    &:hover {
      background: #e3e3e3;
    }
  }

  div.show {
    > #typeMenu {
      background-color: #e3e3e3;
      color: #e3e3e3;
    }
  }

  .dropdown-menu > li > button {
    &:hover,
    &:focus {
      text-decoration: none;
      color: #333333;
      background-color: #e3e3e3;
    }
  }

  .dropdown-header {
    display: grid;
    justify-content: center;
    color: #333333;
    margin: 0;
    font-weight: 700;
    font-size: 13px;
  }
}

.wkp-inspector-container-sidebar {
  right: 40px;
  width: 444px;
  .wkp-inspector-preview {
    flex-grow: 1;
    padding: 20px;

    @at-root #root .wkp-inspector-preview-btn {
      display: block;
      font-weight: bold;
      width: 100%;
    }
  }
}

div#edit-note-container {
  display: flex;
  flex-direction: row;
}

button#edit-note {
  text-transform: capitalize;
  margin-left: 10px;
  font-weight: 500;
}

img#edit-note-icon {
  margin-right: 10px;
}

.wkp-inspector-container .wkp-inspector-warning,
.wkp-inspector-container-sidebar .wkp-inspector-warning {
  margin-top: 20px;
  margin: 0 -20px;
  padding: 20px;
  max-height: 240px;
  overflow-y: auto;
}
