@import '../../variables.scss';

#wkp-app-bar {
  float: left;
  position: relative;
  height: calc(100vh - 42px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: black;
  width: 40px;

  i.fa,
  i.fas {
    color: white;
    font-size: 19px;
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav {
    list-style: none;
    text-align: center;

    li > a:hover,
    li > a:focus {
      background-color: #444;
    }

    .nav-item.active {
      background-color: $btas-crescent-color;
    }
  }
}

.bbna-navbar-hat-slim {
  .navbar-nav > li > a {
    padding-left: 12px;
    padding-right: 12px;
  }
}
