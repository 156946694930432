.cm-fake-banner-wrapper {
  .navbar {
    border: 0px;
  }

  .bbna-navbar-hat-slim {
    height: 40px;
    background-color: #000;
    border-color: #000;
    z-index: 3;
    padding-right: 12px !important;

    i,
    .navbar-nav > li > a,
    .bbna-icon-apps > i {
      font-size: 16px;
      color: white;
    }

    .navbar-nav > li > a.brand-link {
      padding-left: 0px;
    }

    .bbna-icon-apps {
      margin-bottom: 4px;
      width: 16px;

      & > i {
        background: white;
      }
      & > svg {
        height: 16px;
        width: 16px;
      }
    }

    .name-menu-apps {
      margin-left: 7px;
      padding-right: 30px;

      font-size: 14px;
      font-weight: 500;
      color: rgb(255, 255, 255);
    }

    .navbar-nav > li > a {
      line-height: 19px;

      &:focus,
      &:hover {
        background-color: #414141;
        cursor: pointer;
        color: white;
      }
      &[aria-expanded='true'] {
        background-color: #232323;
      }

      &:hover .navbar-button-text {
        color: white;
      }

      .navbar-button-text {
        margin-left: 7px;
        font-weight: 400;
        letter-spacing: 0.6px;
        vertical-align: middle;
        font-size: 14px;
      }

      .navbar-button-icon {
        height: fit-content;
      }

      span.bbui-icon {
        vertical-align: middle;
      }
    }
    .navbar-nav {
      align-items: center;
    }
    .dropdown-menu {
      background-color: rgb(35, 35, 35);
      margin-left: -2px;
      width: 220px !important;
      margin-top: -2px !important;
    }
    .dropdown-menu-apps {
      padding: 10px 0px 0px 10px !important;
    }
    .dropdown-header {
      color: rgb(165, 165, 165);
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 10px;

      &.applications {
        padding-left: 0px;
      }
    }
    .bbna-tiles-container {
      display: flex !important;

      &.dropdown {
        margin-right: 0px;
      }
    }
  }

  .navbar-header {
    padding-left: 12px;
    .navbar-button-text {
      color: #909090;
      font-size: 16px;
      margin-left: 7px;
      letter-spacing: 0.6px;
      display: table-cell;
      height: 40px;
      vertical-align: middle;
    }
  }

  .help {
    li > a {
      cursor: pointer;
    }
  }

  .app-link {
    height: 50px;
    width: 100%;
    display: flex !important;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    box-sizing: content-box;
    padding-left: 10px !important;
    position: relative;
    left: -10px;
    cursor: pointer;
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-color: transparent;
    }

    &:hover {
      background-color: #373737;
    }

    &.disabled {
      pointer-events: none;
    }

    &.active {
      background-color: rgba(0, 115, 255, 0.14);

      &::before {
        background-color: #0062dd;
      }

      &:hover {
        background-color: rgba(0, 115, 255, 0.22);

        &::before {
          background-color: #0062dd;
        }
      }

      &:active {
        background-color: rgba(0, 115, 255, 0.35);
      }
    }

    .bbui-icon {
      font-size: 22px;
    }

    svg {
      height: 100%;
      width: 20px;
    }
  }

  .brand-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 18px;
    color: white;

    .divider {
      height: 20px;
      width: 1px;
      background-color: #646464;
    }

    .brand-title {
      font-size: 20px;
      font-weight: 500;
      padding-right: 20px;
    }
    .brand-product-title {
      padding-left: 20px;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .dropdown.help,
  .dropdown.admin-menu,
  .dropdown.user-profile {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .dropdown.help .dropdown-menu,
  .dropdown.admin-menu .dropdown-menu,
  .dropdown.user-profile .dropdown-menu {
    padding: 14px 0px !important;
    right: -2px;
    svg {
      width: 16px;
      height: 16px;
    }
    a {
      width: 100%;
      gap: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      cursor: pointer;
      text-decoration: none;

      padding: 6px 14px;

      &:hover {
        background-color: rgb(55, 55, 55);
        cursor: pointer;
        color: white;
      }
    }
    .dropdown-header {
      padding: 0px !important;
      margin: 6px 14px;

      height: 20px;
      padding: 6px 14px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 600;
      box-sizing: content-box;
      color: #fff;
    }
  }

  .logout-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

#wkp-app-bar {
  position: fixed !important;
}
.brand-link {
  &:hover {
    text-decoration: none;
    background-color: #000 !important;
    border-color: #000 !important;
  }
}
.help,
.user-profile {
  .dropdown-toggle {
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
